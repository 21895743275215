export type NxtPeopleLayoutProps = Partial<{
   title: string;
   bannerUrl?: string;
   overlayColor?: string;
   tenantLogoUrl?: string;
}>;

export const nxtPeopleLayoutProps = ref<NxtPeopleLayoutProps>();

export function useNxtPeopleLayout(layoutParams: () => NxtPeopleLayoutProps) {
   const layoutProps = computed(layoutParams);

   const defaultValues: NxtPeopleLayoutProps = {
      title: 'NxtPlan',
      bannerUrl: undefined,
      overlayColor: undefined,
      tenantLogoUrl: undefined,
   };

   function updateProps(props: NxtPeopleLayoutProps) {
      nxtPeopleLayoutProps.value = { ...defaultValues, ...props };
   }

   const { pause, resume, stop } = watchPausable(layoutProps, updateProps);

   onActivated(async () => {
      await nextTick();
      resume();
      updateProps(layoutProps.value);
   });
   onMounted(async () => {
      await nextTick();
      resume();
      updateProps(layoutProps.value);
   });
   onDeactivated(pause);
   onBeforeUnmount(stop);
}
